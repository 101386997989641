import React from "react"
import styled from "styled-components"
import axios from "axios"
import { navigate } from "gatsby"
import {
  Button,
  CustomValidationMessage,
  RequiredTriangle,
} from "../../components/FormStyle"
import {
  Checkbox,
  HelpText
} from "../../components/Section"


const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin:0 -10px;
  @media(min-width:768px){
    margin:0 -15px;
  }
`
const FormCol = styled.div`
  position: relative;
  width:100%;
  padding:0 10px;
  @media(min-width:768px){
      padding:0 15px;
      flex: 0 0 50%;
      max-width: 50%;
  }
  @media(min-width:992px){
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
  }
  @media(min-width:1200px){
      flex: 0 0 25%;
      max-width: 25%;
  }
`
const FormColFull = styled.div`
  position: relative;
  width:100%;
  padding:0 10px;
`

const FormButton = styled.div`
    /* button{
        width:100%;
        height:auto;
        margin:0;
    } */
`

const Label = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #062c44;
  margin-bottom:0;
`

const Field = styled.div`
  position: relative;
  margin-bottom:20px;
  @media(min-width:768px){
    margin-bottom:30px;
  }
  .form-control{
    width: 100%;
    background: #fff;
    border: 0px;
    border-bottom: 1px solid #DCE7EF;
    padding:10px 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color:#000;

    &::placeholder {
      color: rgba(103, 107, 121, 0.4);
    }
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      border-color: #062C44;
      &::placeholder {
        color: rgba(103, 107, 121, 0.4);
      }
    }
  }
  textarea.form-control{
    resize:none;
    height:41px;
  }
`

const CustomRequired = styled(CustomValidationMessage)`
  top: 75px;
  left: -20px;
  z-index: 1;
`

class GetInTouchForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      phone_no: "",
      state: "",
      city: "",
      comment: "",
      agreeViaSms: true,
      fNameRequire: false,
      lNameRequire: false,
      emailRequire: false,
      phoneRequire: false,
      stateRequire: false,
      cityRequire: false,
      commentRequire: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleChange(event) {
    const { id, value } = event.target
    if (id !== "agreeViaSms") {
      this.setState({
        [id]: value,
      })
    } else {
      this.setState({
        [id]: event.target.checked,
      })
    }    
    if (id === "first_name") {
      this.setState({
        fNameRequire: false,
      })
    }
    if (id === "last_name") {
      this.setState({
        lNameRequire: false,
      })
    }
    if (id === "email") {
      this.setState({
        emailRequire: false,
      })
    }
    if (id === "phone_no") {
      this.setState({
        phoneRequire: false,
      })
    }
    if (id === "state") {
      this.setState({
        stateRequire: false,
      })
    }
    if (id === "city") {
      this.setState({
        cityRequire: false,
      })
    }
    if (id === "comment") {
      this.setState({
        commentRequire: false,
      })
    }
    if (id === "agreeViaSms") {
      this.setState({
        agreeViaSmsRequire: false,
      })
    }
  }
  handleSubmit = event => {
    const { location, sourceFormName } = this.props
    event.preventDefault()
    if (this.state.first_name === "") {
      this.setState({
        fNameRequire: true,
      })
    } else if (this.state.last_name === "") {
      this.setState({
        lNameRequire: true,
      })
    } else if (this.state.email === "") {
      this.setState({
        emailRequire: true,
      })
    } else if (this.state.phone_no === "") {
      this.setState({
        phoneRequire: true,
      })
    } else if (this.state.state === "") {
      this.setState({
        stateRequire: true,
      })
    } else if (this.state.city === "") {
      this.setState({
        cityRequire: true,
      })
    } else if (this.state.comment === "") {
      this.setState({
        commentRequire: true,
      })
    } else {
      const data = {
        source: location.origin,
        form: sourceFormName,
        page: location.pathname,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        phone_no: this.state.phone_no,
        state: this.state.state_name,
        city: this.state.city,
        comment: this.state.comment + ", SMS consent : " + this.state.agreeViaSms,
        agreeViaSms: this.state.agreeViaSms,
      }
      axios({
        method: "post",
        url: "https://crm.senseicrm.com/api/create-lead-wordpress",
        data: data,
        headers: {
          Authorization:
            "Bearer " +
            "13IMU4ZLF9WyrjRj6JuTfVRwGswQVmfBpO39w7bYvaFT93nbcTKE8PnUBv4bWMrM",
        },
      })
        .then(res => {
          this.setState({
            first_name: "",
            last_name: "",
            email: "",
            phone_no: "",
            zipcode: "",
            state_name: "",
            city: "",
            comment: "",
            agreeViaSms: false,
          })
          navigate("/thanks")
        })
        .catch(error => {
          alert(error)
        })
    }
  }
  render() {
    return (
      <form id="getintouch-form" onSubmit={this.handleSubmit}>
        <FormRow>
          <FormCol>
            <Field>
              <Label>First Name</Label>
              <input className="form-control" id="first_name" placeholder="Please Enter Your First Name" onChange={this.handleChange} pattern="[A-Za-z\s]+$" aria-label="first name" />
              <CustomRequired display={this.state.fNameRequire ? "block" : "none"}><RequiredTriangle /> <span>Please input this field</span></CustomRequired>
            </Field>
          </FormCol>

          <FormCol>
            <Field>
              <Label>Last Name</Label>
              <input className="form-control"
                id="last_name"
                placeholder="Please Enter Your Last Name"
                onChange={this.handleChange}
                pattern="[A-Za-z\s]+$"
                aria-label="last name"
              />
              <CustomRequired
                display={this.state.lNameRequire ? "block" : "none"}
              >
                <RequiredTriangle />
                <span>Please input this field</span>
              </CustomRequired>
            </Field>
          </FormCol>

          <FormCol>
            <Field>
              <Label>Email Address</Label>
              <input className="form-control"
                id="email"
                placeholder="Please Enter Your Email Address"
                onChange={this.handleChange}
                pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                aria-label="email"
              />
              <CustomRequired
                display={this.state.emailRequire ? "block" : "none"}
              >
                <RequiredTriangle />
                <span>Please input this field</span>
              </CustomRequired>
            </Field>
          </FormCol>

          <FormCol>
            <Field>
              <Label>Phone Number</Label>
              <input className="form-control"
                id="phone_no"
                placeholder="Tell us your Personal Number"
                onChange={this.handleChange}
                pattern="[0-9]{10}$"
                aria-label="phone number"
              />
              <CustomRequired
                display={this.state.phoneRequire ? "block" : "none"}
              >
                <RequiredTriangle />
                <span>Please input this field</span>
              </CustomRequired>
            </Field>
          </FormCol>

          <FormCol>
            <Field>
              <Label>State</Label>
              <input className="form-control"
                id="state"
                placeholder="Please Enter Your State"
                onChange={this.handleChange}
                pattern="^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$"
                aria-label="state"
              />
              <CustomRequired
                display={this.state.stateRequire ? "block" : "none"}
              >
                <RequiredTriangle />
                <span>Please input this field</span>
              </CustomRequired>
            </Field>
          </FormCol>

          <FormCol>
            <Field>
              <Label>City</Label>
              <input className="form-control"
                id="city"
                placeholder="Please Enter Your City"
                onChange={this.handleChange}
                pattern="^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$"
                aria-label="city"
              />
              <CustomRequired
                display={this.state.cityRequire ? "block" : "none"}
              >
                <RequiredTriangle />
                <span>Please input this field</span>
              </CustomRequired>
            </Field>
          </FormCol>

          <FormCol>
            <Field>
              <Label>Comment</Label>
              <textarea className="form-control" id="comment" placeholder="Write your comment here…" rows="3" onChange={this.handleChange} aria-label="comment" />
              <CustomRequired display={this.state.commentRequire ? "block" : "none"}> <RequiredTriangle /> <span>Please input this field</span>
              </CustomRequired>
            </Field>
          </FormCol>
        </FormRow>
        <FormRow> 
          <FormColFull>
          <Field>
          <Checkbox>
            <label>
                <input
                  className="form-control"
                  type="checkbox"
                  id="agreeViaSms"
                  name="agreeViaSms"
                  defaultChecked={true}
                  onChange={this.handleChange}
                  aria-label="contacted via SMS"
                />
                {` `}I agree that I will be contacted via SMS.
              </label>
            </Checkbox>
            <HelpText>By submitting this form, I agree to receive text messages from Probuilt Steel Buildings. Standard messaging rates and data charges may apply. Message frequency may vary. You can opt-out by replying STOP at any time or reply Help to get more information. </HelpText>
            </Field>
          </FormColFull>   
          <FormColFull>
            <FormButton><Button type="submit" form="getintouch-form"><div>Submit</div></Button></FormButton>
          </FormColFull>
        </FormRow>        
      </form>
    )
  }
}

export default GetInTouchForm
